import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Cta from "../components/cta";
import Hero from "../components/hero";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

function BusinessAnalytics({ data }) {
    let d = data.contentfulPageBusinessAnalytics;
    return (
        <Layout>
            <SEO
                keywords={d.seo.keywords}
                title={d.seo.title}
                description={d.seo.description}
            />
            
            <Hero
                props={{
                    label: d.label,
                    title: d.title,
                    desc: renderRichText(d.subtitle),
                }}
            />

            <Cta />
        </Layout>
    );
}

BusinessAnalytics.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    {
        contentfulPageBusinessAnalytics {
            seo {
                title
                description
                keywords
            }
            label
            title
            subtitle {
                raw
            }
            features {
                title
                icon
                description
            }
        }
    }
`;

export default BusinessAnalytics;
